import React from "react"

import Layout from "../components/templates/layout"
import SEO from "../components/seo"

import HeroIndex from "../components/organisms/heroIndex"
import HeroOQueOferecemos from "../components/organisms/heros/oQueOferecemos"
import HeroTragaSuasIdeias from "../components/organisms/heros/tragaSuasIdeias"
import HeroSobreNos from "../components/organisms/heros/sobreNos"
import HeroCallToAction from "../components/organisms/heros/callToAction"

const IndexPage = ({ location }) => (
  <Layout fluid={true} lang="pt" location={location}>
    <SEO title="Início" />

    <HeroIndex lang="pt" />
    <HeroOQueOferecemos lang="pt" />
    <HeroTragaSuasIdeias lang="pt" />
    <HeroSobreNos lang="pt" />
    <HeroCallToAction lang="pt" />
  </Layout>
)

export default IndexPage
