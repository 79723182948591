import React from "react"
import styled from "styled-components"
import Link from "../../atoms/link"

import MainContainer from "../../atoms/mainContainer"
import Jumbotron from "react-bootstrap/Jumbotron"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Title from "../../atoms/titleSection"
import CardNossaEquipe from "../../molecules/cardNossaEquipe"

import "../../../utils/fontawesome"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useTranslation } from "../../../utils/translate"

const Hero = styled(Jumbotron)`
  background-color: #ffffff;
  margin-bottom: 0px;
  border-radius: 0px;
`

const SpanTitle = styled.span`
  display: block;
  font-size: 1.4em;
  margin-top: 0.6em;
`

const SpanBox = styled.span`
  display: block;
  width: fit-content;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.4em;
  margin-bottom: 0.4em;
  padding-left: 0.3em;
  padding-right: 0.3em;
`

const SobreNos = ({ lang }) => {
  
  const t = useTranslation(lang, "sectionAboutUs")

  return (
    <Hero>
      <MainContainer>
        <Title>{t("title")}</Title>
        <Container>
          <Row className="align-items-center">
            <Col xs={12} md={7} lg={8}>
              <CardNossaEquipe className="mb-5" lang={lang} />
            </Col>
            <Col xs={12} md={5} lg={4} className="align-self-middle d-none d-md-block">
              <SpanBox style={{backgroundColor: "#F39237"}}>{t("highlight.quality")}</SpanBox>
              <SpanBox style={{backgroundColor: "#003D5B"}}>{t("highlight.inovation")}</SpanBox>
              <SpanBox style={{backgroundColor: "#30638E"}}>{t("highlight.research")}</SpanBox>
              <SpanTitle>{t("dna")}</SpanTitle>
            </Col>
          </Row>
          <Row>
            <Link to="/equipe" className="px-4 mt-3" lang={lang}>
              {t("more")}
              <FontAwesomeIcon icon="chevron-right" size="xs" className="ml-2" />
            </Link>
          </Row>
        </Container>
      </MainContainer>
    </Hero>
  )
}

export default SobreNos
