import React from "react"
import Link from "../../atoms/link"

import styled from "styled-components"

import MainContainer from "../../atoms/mainContainer"
import Jumbotron from "react-bootstrap/Jumbotron"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Button from "../../atoms/button"
import CardAtividade from "../../molecules/cardAtividade"

import { useTranslation } from "../../../utils/translate"

const Hero = styled(Jumbotron)`
  background-color: #2C2C2C;
  text-align: center;
  margin-bottom: 0px;
  border-radius: 0px;
  color: #F8F8F8;
`

const SpotText = styled.span`
  color: #F8F8F8;
  font-size: 1.3em;
`

const Text = styled.p`
  color: #F8F8F8;
  font-size: 1em;
  opacity: 0.7;
  margin-bottom: 4.6rem;
`

const atividades = [
  {
    key: "newBusiness",
    icon: "briefcase"
  },
  {
    key: "interface",
    icon: "object-group"
  },
  {
    key: "webDevelopment",
    icon: "mobile-alt"
  },
];

const HeroSection = ({ lang }) => {

  const t = useTranslation(lang, "secondSection")

  return (
    <Hero data-sal="slide-right" data-sal-duration="3000" data-sal-delay="200">
      <MainContainer>
        <SpotText style={{textTransform: "uppercase"}}>{t("title")}</SpotText>
        <Text className="px-4">
        {t("subtitle")}
        </Text>

        <SpotText className="mt-5">{t("title2")}</SpotText>
        <Container className="mt-4 px-lg-5 px-sm-5">
          <Row data-sal="slide-right" data-sal-duration="300" data-sal-delay="600">
            {
              atividades.map(atividade => (
                <Col xs={12} md={4} className="mb-3">
                  <CardAtividade title={t(`services.${atividade.key}.title`)} icon={atividade.icon} />
                </Col>
              ))
            }
          </Row>
        </Container>

        <Link to="/contato" lang={lang}>
          <Button variant="outline-light" className="mt-3">{t("button")}</Button>
        </Link>
      </MainContainer>
    </Hero>
  )
}

export default HeroSection
