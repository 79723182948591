import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Link from "../../atoms/link"

import styled from "styled-components"

import MainContainer from "../../atoms/mainContainer"
import Jumbotron from "react-bootstrap/Jumbotron"

import BackgroundImage from "gatsby-background-image"

import Title from "../../atoms/titleSection"
import Button from "../../atoms/button"

import { useTranslation } from "../../../utils/translate"

const Hero = styled(Jumbotron)`
  background-color: rgba(80, 80, 80, 0.7);
  margin-bottom: 0px;
  border-radius: 0px;
  text-align: center;
`

const TitleStyled = styled(Title)`
  color: #F8F8F8;
  text-transform: uppercase;
`

const ParallaxBackgroundImage = styled(BackgroundImage)`
  background-attachment: fixed;
  background-size: cover;
  background-position: 50% 50%;
`

const HeroSection = ({ lang }) => {

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "hero_cta_banner.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const t = useTranslation(lang, "heroCallToAction")

  return (
    <ParallaxBackgroundImage
        fluid={data.file.childImageSharp.fluid}
    >
      <Hero>
        <MainContainer>
          <TitleStyled>
            {t("text1")}<br/>{t("text2")}
          </TitleStyled>

          <Link to="/contato" lang={lang}>
            <Button variant="light" className="px-5">{t("button")}</Button>
          </Link>
        </MainContainer>
      </Hero>
    </ParallaxBackgroundImage>
  )
}

export default HeroSection
