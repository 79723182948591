import React from "react"

import styled from "styled-components"

import MainContainer from "../../atoms/mainContainer"
import Jumbotron from "react-bootstrap/Jumbotron"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Title from "../../atoms/titleSection"
import CardProduto from "../../molecules/cardProduto"

import { useTranslation } from "../../../utils/translate"

const Hero = styled(Jumbotron)`
  background-color: #efefef;
  margin-bottom: 0px;
  border-radius: 0px;
`

const produtos = [
  {
    key: "startup",
    icon: "business-time",
  },
  {
    key: "mobile",
    icon: "mobile-alt",
  },
  {
    key: "ui",
    icon: "palette",
  },
  {
    key: "ux",
    icon: "thumbs-up",
  },
  {
    key: "software",
    icon: "database",
  },
  {
    key: "website",
    icon: "globe",
  },
  {
    key: "management",
    icon: "tasks",
  },
  {
    key: "digitalTransformation",
    icon: "puzzle-piece",
  },
];


const HeroSection = ({ lang }) => {

  const t = useTranslation(lang, "products")

  return (
    <Hero>
      <MainContainer>
        <Title
          className="animated fadeIn"
          style={{animationDelay: `200ms`, animationDuration: `1300ms`}}
        >{t("title")}</Title>
        <Container>
          <Row>
            {produtos.map((produto, index) => (
              <Col xs="6" lg="3" className="mb-2 mb-lg-4 px-1 px-lg-2">
                <div
                  className="animated fadeInUp h-100"
                  style={{animationDelay: `${(index*100) + 200}ms`}}
                >
                  <CardProduto
                    title={t(`${produto.key}.title`)}
                    subtitle={t(`${produto.key}.subtitle`)}
                    icon={produto.icon}
                    key={`prod-${produto.key}`}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </MainContainer>
    </Hero>
  )
}

export default HeroSection
