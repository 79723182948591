import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Link from "../atoms/link"
import Img from "gatsby-image"

import styled from "styled-components"
import { primaryColor } from "../../colors"

import MainContainer from "../atoms/mainContainer"
import Jumbotron from "react-bootstrap/Jumbotron"
import Button from "../atoms/button"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import BackgroundImage from "gatsby-background-image"

import { useTranslation } from "../../utils/translate"

const Hero = styled(Jumbotron)`
  background-color: rgba(8, 99, 117, 0.95);
  margin-bottom: 0px;
  border-radius: 0px;
  padding-top: 1em;
  padding-bottom: 1em;

  color: #ffffff !important;
`

const TextLine = styled.span`
  display: block;
  font-size: 1.8em;
  color: color.scale(${primaryColor}, $lightness: 20%);
  line-height: 1.4em;
`

const HeroText = styled.div`
  @media screen and (max-width: 500px) {
    font-size: 0.7em;
  }
`

const ParallaxBackgroundImage = styled(BackgroundImage)`
  background-attachment: fixed;
  background-size: cover;
  background-position: 50% 50%;
`

const HeroIndex = ({ lang }) => {

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "hero_banner.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      isometricImg: file(relativePath: { eq: "hero_banner_isometric.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  const t = useTranslation(lang, "banner")

  return (
    <ParallaxBackgroundImage
      fluid={data.file.childImageSharp.fluid}
    >
      <Hero>
        <MainContainer>
          <Container>
            <Row className="align-items-center">
              <Col className="py-5">
                <HeroText>
                  <TextLine className="animated fadeInLeft" style={{animationDelay: "50ms"}}>
                    <span style={{opacity: "0.75"}}>Web Design,</span>
                  </TextLine>
                  <TextLine className="animated fadeInLeft" style={{animationDelay: "100ms"}}>
                    <span style={{opacity: "0.75"}}>Web Development &amp;</span>
                  </TextLine>
                  <TextLine className="animated fadeInLeft" style={{animationDelay: "150ms"}}>
                    <span style={{fontWeight: "bold"}}>Digital Entrepreneurship</span>
                  </TextLine>
                </HeroText>
                <div className="animated fadeInLeft" style={{animationDelay: "300ms"}}>
                  <Link to="/contato" lang={lang}>
                    <Button variant="outline-light" className="mt-4">{t("explorePortfolio")}</Button>
                  </Link>
                </div>
              </Col>
              <Col className="d-none d-lg-block align-self-end">
                <Img
                  fluid={data.isometricImg.childImageSharp.fluid}
                  style={{maxHeight: "400px", maxWidth: "400px", animationDelay: "400ms"}}
                  className="animated fadeIn"
                />
              </Col>
            </Row>
          </Container>
        </MainContainer>
      </Hero>
    </ParallaxBackgroundImage>
  )
}

export default HeroIndex
